import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import steamMp4 from './steam-hevc.mp4'
import steamWebm from './steam-vp9.webm'
import { getSteamFromConfig, getSteamToConfig, mmConfig } from '/util/gsapHelpers'

import { images } from '/images'
import styles from './Timeline.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

export function Timeline() {
  const introTitleRef = React.useRef(null)
  const introTextRef = React.useRef(null)
  const outroTitleRef = React.useRef(null)
  const outroTextRef = React.useRef(null)
  const panRef = React.useRef(null)

  useGSAP(() => {
    const mm = gsap.matchMedia()

    mm.add(mmConfig,
      (ctx) => {
        const { isMobile } = ctx.conditions
        addHeadingSteamAnimation(introTitleRef, introTextRef, 2)
        addHeadingSteamAnimation(outroTitleRef, outroTextRef, 2)

        function addHeadingSteamAnimation(titleRef, textRef, firstWord) {
          const title = new SplitText(titleRef.current, { types: 'words' })

          const tlIntro = gsap.timeline({
            scrollTrigger: {
              trigger: titleRef.current,
              start: 'top 80%',
            }
          })
          tlIntro
            .fromTo( title.words, getSteamFromConfig(isMobile), getSteamToConfig({ each: 0.10 }) )
            .fromTo(textRef.current, { opacity: 0 }, { opacity: 1, duration: 1.5, ease: 'power1.out' }, '-=1.2')
        }
      }
    )
  })

  return (
    <div className={styles.component}>
      <img src={images.harbour} alt="" className={styles.bgImage} />

      <div className={styles.intro}>
        <h2 ref={introTitleRef} className={styles.introTitle}>
          Neem een<br /> stichting uit 1899
        </h2>
        <p ref={introTextRef} className={styles.introText}>
          en voeg daar goed eten en drinken voor haven- en spoorarbeiders aan toe.
        </p>
      </div>

      {/* PanAndSteam component must be before YearCounter component, otherwise panRef passed to YearCounter is null */}
      <PanAndSteam ref={panRef} />

      <YearCounter layoutClassName={styles.yearCounterLayout} {...{ panRef }} />

      <div className={styles.outro}>
        <h2 ref={outroTitleRef} className={cx(styles.outroTitle)}>
          Laat tenminste<br /> 125 jaar pruttelen
        </h2>
        <p ref={outroTextRef} className={styles.outroText}>
          en breng regelmatig op smaak met<br /> nieuwe locaties en passie voor lekker,<br /> gezond, duurzaam en betaalbaar eten.
        </p>
      </div>
    </div>
  )
}

const PanAndSteam = React.forwardRef(function PanAndSteam(props, panRef) {
  const fireRef = React.useRef(null)

  useGSAP(() => {
    ScrollTrigger.create({
      trigger: fireRef.current,
      start: 'top bottom',
      end: 'bottom top',
      id: 'panwobble',
      toggleClass: {
        targets: [
          fireRef.current,
          panRef.current,
        ],
        className: styles.isWobbling,
      },
    })
  })

  return (
    <section className={cx(styles.componentPanAndSteam)}>

      {/* https://rotato.app/blog/transparent-videos-for-the-web */}
      <video className={styles.steamVideo} autoPlay loop muted playsInline>
        { /* keep hvc1 before webm for safari. Safari does support vp9/webm, but not its transparency */ }
        <source src={steamMp4} type='video/mp4; codecs="hvc1"' />
        <source src={steamWebm} type="video/webm" />
        Uw browser ondersteunt geen video-element.
      </video>

      <img src={images.cookingPanShadow} alt="" className={cx(styles.panAndFireImageStack, styles.cookingPanShadowImage)} />
      <img ref={fireRef} src={images.fire} alt="gasvlam" className={cx(styles.panAndFireImageStack, styles.fireImage)} />
      <img ref={panRef} src={images.cookingPan} alt="oud kookpannetje" className={cx(styles.panAndFireImageStack, styles.cookingPanImage)} id="cookingPanImage" data-cooking-pan-image />
    </section>
  )
})

function YearCounter({ layoutClassName, panRef }) {
  const componentRef = React.useRef(null)
  const thousandsRef = React.useRef(null)
  const hundredsRef = React.useRef(null)
  const tensRef = React.useRef(null)
  const singleRef = React.useRef(null)
  const startYear = 1899
  const todayYear = new Date().getFullYear()

  useGSAP(() => {
    const counterRect = componentRef.current.getBoundingClientRect()
    const panRect = panRef.current.getBoundingClientRect()
    const yearCounterBottomY = counterRect.y + counterRect.height
    const panCenterY = panRect.y + panRect.height * 0.4 // center of pan is at around 0.4 of image height
    const dy = panCenterY - yearCounterBottomY
    const dyStr = dy > 0 ? `${dy}px` : '100%' // in some non-reproducible cases, yearCounter moves up instead of down. I couldn't figure out why; use 100% as fallback in those cases

    // counter
    ScrollTrigger.create({
      trigger: componentRef.current,
      endTrigger: panRef.current,
      start: 'bottom 105%',
      end: 'top 35%',
      scrub: 1,
      onUpdate: updateCounter,
      id: 'year-counter',
    })

    // vertical movement
    gsap.to(componentRef.current, {
      scrollTrigger: {
        trigger: panRef.current,
        start: 'top 90%',
        end: 'top top',
        scrub: 0.75,
      },
      y: `+=${dyStr}`,
      id: 'year-y',
    })

  })

  const classNames = cx(styles.yearCounterComponent, layoutClassName)

  function updateCounter({ progress }) {
    const currYear = Math.min(todayYear, Math.ceil(startYear + (1.1 * progress * (todayYear - startYear))))
    const yearStr = currYear.toString()
    if (componentRef?.current) {
      thousandsRef.current.innerText = yearStr.charAt(0)
      hundredsRef.current.innerText = yearStr.charAt(1)
      tensRef.current.innerText = yearStr.charAt(2)
      singleRef.current.innerText = yearStr.charAt(3)
    }
  }

  return (
    <div ref={componentRef} className={classNames}>
      <span ref={thousandsRef} className={styles.thousandsCounter}>1</span>
      <span ref={hundredsRef}>8</span>
      <span ref={tensRef}>9</span>
      <span ref={singleRef}>9</span>
    </div>
  )
}
